<template>
    <div data-collapse="medium" data-animation="over-right" data-duration="600" data-easing="ease-out-expo"
        data-easing2="ease-out-expo" data-w-id="58db7844-5919-d71b-dd74-2323ed8dffe9" role="banner" class="header w-nav">
        <div class="container-header">
            <div data-w-id="24f3b6a9-0e52-5423-5a8f-426121254c7d" class="split-content header-left" style="opacity: 1;"><a
                    href="/" aria-current="page" class="brand w-nav-brand w--current" aria-label="home"><img
                        src="/img/Logo.png" height="40" alt="Crydict App - Logo" class="header-logo"></a>
                <div class="divider header"></div>
                <nav role="navigation" class="nav-menu w-nav-menu">
                    <ul role="list" class="header-navigation">
                        <li class="nav-item-wrapper">

                            <RouterLink :to="`/`" class="nav-link w--current">Home</RouterLink>
                        </li>
                        <li class="nav-item-wrapper">
                            <RouterLink :to="`/predictions`" class="nav-link w--current">Predictions</RouterLink>
                        </li>

                        <li class="nav-item-wrapper mobile"><a href="https://app.crydict.com" target="_blank"
                                class="button-primary header-button mobile w-button">Try for Free</a></li>
                    </ul>
                </nav>
            </div>
            <div data-w-id="e4e11b47-c85c-d5aa-e8d9-e188d8e54135" class="split-content header-right" style="opacity: 1;">

                <a href="https://app.crydict.com" target="_blank" class="button-primary header-button w-button">Try for
                    Free</a>
                <div class="menu-button w-nav-button" style="-webkit-user-select: text;" aria-label="menu" role="button"
                    tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
                    <div class="menu-button-wrapper">
                        <div class="menu-button-icon">
                            <div class="menu-line-top"></div>
                            <div class="menu-line-middle"></div>
                            <div class="menu-line-bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
    </div>
    <!-- ariane feed -->
    <transition name="fade">
        <div class="ariane-feed">
            <div class="container-default w-container" v-if="$route.path != '/'">
                <div class="ariane-wrapper">
                    <div class="ariane-content">
                        <div class="ariane-link-wrapper">
                            <RouterLink :to="`/`" class="ariane-link">Home</RouterLink>
                            <div class="divider"></div>
                        </div>
                        <div class="ariane-link-wrapper" v-for="(link, index) in arianeFeed" :key="index">
                            <RouterLink :to="link.link" class="ariane-link">{{ link.name }}</RouterLink>
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    </template>

<script>
export default {
    name: 'MenuComponent',
    computed:{
        arianeFeed(){
            let sublink = this.$route.path.split('/');
            let ariane = [];
            let link = '';
            for(let i = 0; i < sublink.length; i++){
                if(sublink[i] != ''){
                    link += '/' + sublink[i];
                    // capitalize first letter
                    sublink[i] = sublink[i].charAt(0).toUpperCase() + sublink[i].slice(1);
                    ariane.push({name: sublink[i], link: link});
                }
            }
            return ariane;
        }
    }
}
</script>

<style lang="scss">
// ariane feed css
.ariane-feed {
    padding: 20px 0;
    height:100px;
    .ariane-wrapper {
        display: flex;
        justify-content: center;
        .ariane-content {
            display: flex;
            align-items: center;
            .ariane-link-wrapper {
                display: flex;
                align-items: center;
                .ariane-link {
                    font-size: 14px;
                    font-weight: 500;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .divider {
                    margin: 0 10px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }
        }
    }
}
</style>
<template>
    <footer class="footer">
        <div class="container-default w-container">
            <div data-w-id="0b01e733-fc96-feb8-5980-aa691c8cc058" class="top-content footer-top-content"
                style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 1;">
                <a href="/" aria-current="page" class="footer-logo-container w-inline-block w--current"><img
                        src="/img/Logo.png" alt="Crydict App - Logo" class="footer-logo"></a>
                <div class="w-layout-grid footer-social-media-grid">
                    <a href="https://twitter.com/CrydictApp" target="_blank"
                        class="footer-social-media-link w-inline-block">
                        <div></div>
                    </a><a href="https://www.instagram.com/crydict" target="_blank"
                        class="footer-social-media-link w-inline-block">
                        <div></div>
                    </a>
                </div>
            </div>
            <div class="footer-content">
                <div class="footer-links-block">
                    <div data-w-id="44911c68-4879-735a-8d49-bd2d9f68fe8c" class="links-block"
                        style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 1;">
                        <div class="footer-mobile-title">
                            <h2 class="title-small-uppercase footer-tilte-links">Menu</h2>
                        </div>
                        <div class="footer-mobile-content">
                            <div class="footer-content-links">
                                <ul role="list" class="list-footer w-list-unstyled">
                                    <li class="footer-list-item"><a href="/" class="footer-link">Home</a></li>
                                    <li class="footer-list-item"><a  target="_blank" href="https://lvaholding.notion.site/Terms-of-User-Privacy-1e767974ca394eadbfbe628e49b7f773?pvs=4" class="footer-link">Terms & Conditions</a></li>
                                    <li class="footer-list-item last"><a href="https://discord.gg/y6PQtwnKDe" class="footer-link">Discord</a>
                                    </li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider footer-divider"></div>
        <div class="small-print-wrapper">
            <div class="text-block-2">Copyright © Crydict
            </div>
        </div>
    </div>
</footer></template>

<script>
export default {
    name: 'FooterComponent'
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/predictions',
    name: 'predictions',
    component: () => import('../views/PredictionView.vue'),
    children: [
      {
        path: '',
        name: 'predictions',
        component: () => import('../components/StocksList.vue')
      },
      {
        path: ':slug',
        name: 'prediction',
        component: () => import('../components/PredictionComponent.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// canaonical
router.beforeEach((to) => {
  // set canonical
  const canonical = document.querySelector('link[rel="canonical"]')
  if (canonical) {
    canonical.href = window.location.origin + to.fullPath
  } else {
    const link = document.createElement('link')
    link.setAttribute('rel', 'canonical')
    document.head.appendChild(link)
    link.href = window.location.origin + to.fullPath
  }
})

export default router

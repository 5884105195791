<template>
  <div data-w-id="ccb95917-ff32-4fc4-37fc-c11e935ea970" class="section home-hero">
    <div class="container-default w-container">
      <div class="home-hero-wrapper">
        <div class="home-hero-content">
          <h1 data-w-id="af0f8c2c-b25d-4bcb-8f3a-1b2a20d5286b"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="title home-hero">Precision Crypto Predictions and Real-Time Trading Signals</h1>
          <p data-w-id="80a79125-8d34-e7c0-9d04-cf48bda1bcd3"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="paragraph home-hero">Explore the power of Crydict, your ultimate companion for precise
            cryptocurrency market predictions. Gain real-time insights, reliable trading signals, and unparalleled
            visibility into market opportunities.</p>
          <div data-w-id="cd6cdd2e-e176-11e0-b06b-9ccef8ea9349"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="_2-buttons-wrapper"><a href="https://app.crydict.com" class="button-primary w-button">Try for Free</a>
          </div>
        </div>
        <div class="images-wrapper home-hero"><img class="image home-hero-1" src="img/Desktop-capture-2.png" width="1161"
            alt="Crypto Trading Web App - Crydict App"
            style="opacity: 1; will-change: transform; transform: translate3d(0px, 1.8938%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            sizes="(max-width: 479px) 93vw, (max-width: 767px) 94vw, (max-width: 1439px) 95vw, 1161px"
            data-w-id="32cc4b65-e2b7-f258-fc50-40e6085f60b9"
            srcset="img/Desktop-capture-2.png 500w, img/Desktop-capture-2.png 800w, img/Desktop-capture-2.png 1080w, img/Desktop-capture-2.png 1600w, img/Desktop-capture-2.png 2322w"><img
            src="img/Mobile-capture.png" alt="Crypto Trading App - Crydict App"
            style="opacity: 1; will-change: transform; transform: translate3d(0px, -1.8938%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
            data-w-id="bfb2c32d-d183-bae6-79a2-fb105a75a0a1" class="image home-hero-2"></div><img
          src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fad5b5aab11b7ddf2b9770a_bg-home-hero-2-crypto-template.svg"
          alt="" class="bg home-hero-2"
          style="will-change: transform; transform: translate3d(-9.998px, 7.4252px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"><img
          src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fad5b5a65232192e2e1b21e_bg-home-hero-1-crypto-template.svg"
          alt="" class="bg home-hero-1"
          style="will-change: transform; transform: translate3d(39.992px, -29.7008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
      </div>
    </div>
  </div>
  <div data-w-id="0376f97b-fe26-5952-f644-26a6fe569cf2" class="section section-about">
    <div class="container-medium-767px section-about">
      <h2>Unlocking Profitable Opportunities with Crydict</h2>
      <p class="paragraph section-about">Unleash profits with Crydict. Our AI-driven platform delivers real-time
        crypto insights, short-term predictions, and instant signals. Take charge, make informed decisions, and unlock
        lucrative opportunities in crypto trading.</p>
    </div>
    <div class="container-default w-container">
      <div class="section-about-wrapper">
        <div class="w-layout-grid section-about-grid">
          <div class="card section-about">
            <div class="image-wrapper section-about-icon"><img
                src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac290fdabe272854dfde46_icon-section-about-1-crypto-template.svg"
                alt="Send and Receive Icon - Crydict App" class="image section-about-icon"></div>
            <h3 class="title-small-uppercase">Sync every 10 min</h3>
            <p class="paragraph-small">
              Crydict Algorithm sync every 10 minutes to ensure you have the most up-to-date predictions and signals.
            </p>
          </div>
          <div id="w-node-_0d12b600-d5e6-f40d-fcbc-e693682be919-5e96eb36" class="card section-about-featured">
            <h3 class="title-small-uppercase section-about-featured">On your browser</h3>
            <p class="paragraph-small section-about-featured">
              Crydict is available on your browser, so you can access it from any device, anywhere in the world.
            </p><img src="img/Mobile-capture-2.png" alt="Trading Crypto - Crydict App"
              sizes="100vw" srcset="img/Mobile-capture-2.png 500w, img/Mobile-capture-2.png 822w"
              class="image section-about-featured">
            <div class="image section-about-featured-shadow"></div>
          </div>
          <div class="card section-about">
            <div class="image-wrapper section-about-icon"><img
                src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac290f6bfe493ebb2e7c0f_icon-section-about-2-crypto-template.svg"
                alt="Trading Charts Icon - Crydict App" class="image section-about-icon"></div>
            <h3 class="title-small-uppercase">Trading Charts</h3>
            <p class="paragraph-small">
              Trading charts are available for all cryptocurrencies and are updated in real-time.
            </p>
          </div>
          <div class="card section-about">
            <div class="image-wrapper section-about-icon"><img
                src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac290fa193a3653df7fd92_icon-section-about-3-crypto-template.svg"
                alt="Secure Wallet Icon - Crydict App" class="image section-about-icon"></div>
            <h3 class="title-small-uppercase">Best Cryptocurrencies</h3>
            <p class="paragraph-small">
              Crydict supports all major cryptocurrencies, including Bitcoin, Ethereum, XRP, and more.
            </p>
          </div>
          <div class="card section-about">
            <div class="image-wrapper section-about-icon"><img
                src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac290fec506e73d23870fc_icon-section-about-4-crypto-template.svg"
                alt="Real Time Analytics Icon - Crydict App" class="image section-about-icon"></div>
            <h3 class="title-small-uppercase">Forecast</h3>
            <p class="paragraph-small">
              Forecast are available for all cryptocurrencies and are updated in real-time.
            </p>
          </div>
        </div>
        <div class="flex-vc"><a href="https://app.crydict.com" class="button-primary w-button">Try for Free</a></div><img
          src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fad5e25a1566a73dfc66f3b_bg-section-about-crypto-template.svg"
          alt="" class="bg section-about"
          style="will-change: transform; transform: translate3d(39.992px, -29.7008px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
      </div>
    </div>
  </div>
  <div data-w-id="3e6f94db-2878-72fa-8eed-bfea34ee3966" class="section specs-1">
    <div class="container-default w-container">
      <div class="specs-1-wrapper">
        <div class="images-wrapper specs-1"><img src="img/Mobile-capture-3.png" alt="Browse Transactions - Crydict App"
            width="314" class="image specs-1-mockup-1"
            style="will-change: transform; transform: translate3d(0px, 30%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"><img
            src="img/Mobile-capture-2.png" alt=" Send Crydict- Crydict App" width="305" class="image specs-1-mockup-2"
            style="will-change: transform; transform: translate3d(0px, 30%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
        </div>
        <div class="split-content specs-1">
          <h2 data-w-id="37c06a75-e60b-1c51-bc53-056f62f34e8e"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;">
            How Crydict Works ?</h2>
          <p data-w-id="bef0e92c-a109-6725-e869-2892ac0e277c"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="paragraph specs-1">Empowering your crypto journey is our priority. Crydict simplifies the complex
            world of crypto trading through</p>
          <div data-w-id="801ddb42-8674-6139-af0e-4cc09ced0238"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="specs-wrapper">
            <div class="spec-wrapper">
              <div class="image-wrapper spec-icon"><img
                  src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac2c28a34e975d405fc7c4_icon-spec-1-1-crypto-template.svg"
                  alt="Lowest Fees Icon - Crydict App" class="image spec-icon"></div>
              <div class="paragraph-medium specs">Advanced AI Analysis.</div>
            </div>
            <div class="spec-wrapper">
              <div class="image-wrapper spec-icon"><img
                  src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac2c2725e486974723a81e_icon-spec-1-2-crypto-template.svg"
                  alt="Fast and Secure Icon - Crydict App" class="image spec-icon"></div>
              <div class="paragraph-medium specs">Short-Term Predictions</div>
            </div>
            <div class="spec-wrapper last">
              <div class="image-wrapper spec-icon"><img
                  src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac2c28a34e974f425fc7c5_icon-spec-1-3-crypto-template.svg"
                  alt="Secure Encryption - Crydict App" class="image spec-icon"></div>
              <div class="paragraph-medium specs">Instant Buy/Sell Signals</div>
            </div>
          </div>
        </div><img
          src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fad5f4044981a7c97dd4154_bg-specs-crypto-template.svg"
          alt="" class="bg specs"
          style="will-change: transform; transform: translate3d(59.988px, -44.5512px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
      </div>
    </div>
  </div>
  <div data-w-id="be806f50-c87d-59f8-fb46-cdc037bdb565" class="section specs-2">
    <div class="container-default w-container">
      <div class="specs-2-wrapper">
        <div class="split-content specs-1">
          <h2 data-w-id="2f0d875d-5322-2cc0-8026-fb9374d0e27d"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;">
            Built on a robust and powerful platform</h2>
          <p data-w-id="2f0d875d-5322-2cc0-8026-fb9374d0e27f"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="paragraph specs-1">At the core of Crydict lies a robust and powerful platform designed to redefine
            your crypto trading experience. Our cutting-edge technology offer :</p>
          <div data-w-id="6d2492e4-7fb0-3d97-9b42-105fb9b13ad1"
            style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
            class="specs-wrapper">
            <div class="spec-wrapper">
              <div class="image-wrapper spec-icon"><img
                  src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac2e3576598133f1fc184c_icon-spec-2-1-crypto-template.svg"
                  alt="100% Private Data - Crydict App" class="image spec-icon"></div>
              <div class="paragraph-medium specs">100% Private data</div>
            </div>
            <div class="spec-wrapper">
              <div class="image-wrapper spec-icon"><img
                  src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac2e36fe880a06239cc888_icon-spec-2-2-crypto-template.svg"
                  alt="99% Uptime Guarantee - Crydict App" class="image spec-icon"></div>
              <div class="paragraph-medium specs">99.99% Uptime guarantee</div>
            </div>
            <div class="spec-wrapper last">
              <div class="image-wrapper spec-icon"><img
                  src="https://assets-global.website-files.com/5f8f28722b0eae892596eb35/5fac2e3611ab43e826e33274_icon-spec-2-3-crypto-template.svg"
                  alt="24/7 Support - Crydict App" class="image spec-icon"></div>
              <div class="paragraph-medium specs">24/7 Dedicated support</div>
            </div>
          </div>
        </div><img src="img/Desktop-capture.png" alt="Crypto Trading Web App - Crydict App" sizes="100vw"
          srcset="img/Desktop-capture.png 500w, img/Desktop-capture.png 800w, img/Desktop-capture.png 2117w"
          class="image specs-2"
          style="will-change: transform; transform: translate3d(0px, 30%, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
      </div>
    </div>
  </div>
  <div data-w-id="6c848a54-fca6-0008-67e5-d4d81cea669e" class="section cta-v1">
    <CTAComponent />
  </div>
</template>

<script>
import CTAComponent from '@/components/CTAComponent.vue';


export default {
  name: 'HomeView',
  components: {
    CTAComponent
  },
  data:() => ({
    meta_title: 'Crydict: Real-Time Crypto Predictions and Nowcasts',
    meta_description: 'Unlock profitable opportunities with Crydict, the ultimate AI-driven platform for real-time crypto predictions, short-term forecasts, and instant buy/sell signals. Empower your crypto journey and make informed decisions for maximum profitability. Download Crydict now.',
  }),
  mounted() {
    document.title = this.meta_title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.meta_description);
  }
}
</script>

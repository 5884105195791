<template>
    <div class="bg cta-v1">
        <div class="container-default w-container" style="overflow:hidden;">
            <div class="cta-v1-wrapper">
                <div class="split-content cta-v1">
                    <h2 data-w-id="6c848a54-fca6-0008-67e5-d4d81cea66a3"
                        style="transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style:preserve-3d;opacity:1;">
                        Try the future, today.<br>Try Crydict for Free</h2>
                    <p data-w-id="6c848a54-fca6-0008-67e5-d4d81cea66a7" class="paragraph cta-v1"
                        style="transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style:preserve-3d;opacity:1;">
                        Unlock the potential for profitable trades. Your crypto journey begins with Crydict.</p>
                    <div data-w-id="6c848a54-fca6-0008-67e5-d4d81cea66a9" class="animation-div"
                        style="transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style:preserve-3d;opacity:1;">
                        <a href="https://app.crydict.com" class="button-primary white w-button">Try for Free</a>
                    </div>
                </div>
                <div class="images-wrapper cta-v1"><img src="/img/Mobile-capture.png" alt="Cryptocurrencies - Crydict App"
                        sizes="(max-width: 767px) 45vw, (max-width: 991px) 362.82122802734375px, (max-width: 1439px) 37vw, 362.8212890625px"
                        srcset="/img/Mobile-capture.png 500w, /img/Mobile-capture.png 679w" class="image cta-v1-1"
                        style="will-change:transform;transform:translate3d(0px, 30%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style:preserve-3d;"><img
                        src="/img/Mobile-capture-2.png" alt="Trading Crypto - Crydict App"
                        sizes="(max-width: 767px) 50vw, (max-width: 991px) 430.7052001953125px, (max-width: 1439px) 430.70513916015625px, 30vw"
                        srcset="/img/Mobile-capture.png 500w, /img/Mobile-capture-2.png 822w" class="image cta-v1-2"
                        style="will-change:transform;transform:translate3d(0px, 30%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style:preserve-3d;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CTAComponent'
}
</script>